import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";
import { Grid, ListItem, Paper, Typography } from "@mui/material";
import _ from 'lodash';
import { CheckCircle } from "lucide-react";
import { useEffect, useState } from "react";
import ProgramableField from "./ProgramableField";

const variableRegex = /\{\{([^}]+)\}\}/g;

export const evaluateCondition = (rule: any, values: any, requestSale: any): boolean => {
    const matches = rule?.field && rule.field.indexOf('{{') !== -1 && rule.field.indexOf('}}') !== -1
        ? [...rule.field.matchAll(variableRegex)].map(match => match[1].trim())
        : [rule.field];;

    if (matches.length === 0) return false;
    const keys = matches[0].split('.');

    let valueUse: string = '';

    const { status } = requestSale || {}
    const requestValues = { ...values, status, estado: status }

    let currentField: { [key: string]: any } = {
        solicitud: requestValues,
        request: requestValues,
        status,
        ...values
    };

    for (let key of keys) {
        const lowerKeys = Object.keys(currentField).reduce((acc, k) => {
            acc[k.toLowerCase()] = currentField[k];
            return acc;
        }, {} as Record<string, any>);

        const formatKey = key.toLowerCase();

        if (!lowerKeys[formatKey]) break;

        if (typeof lowerKeys[formatKey] === 'object' && !Array.isArray(lowerKeys[formatKey])) {
            currentField = lowerKeys[formatKey];
        } else {
            valueUse = lowerKeys[formatKey];
        }
    }

    const { value } = rule || {};
    const valueToArray = [valueUse].flat();

    switch (rule.operator) {
        case 'equal':
            return valueToArray.some((fieldValue) => fieldValue == value);
        case 'notEqual':
            return !valueToArray.includes(rule.value);
        case 'in':
            const ruleValueToArray = (Array.isArray(value) ? value : (value || '').split(','));
            return valueToArray.some((fieldValue) => ruleValueToArray.includes(fieldValue))
        default:
            return false;
    }
};

export const evaluateRules = (rules: any[], values: any, requestSale: any): boolean => {
    if (rules.length == 0) return true;

    let visibility: boolean = false;
    let pendingOperator: null | string = null;

    for (let j = 0; j < rules.length; j++) {
        const currentRule = rules[j];
        const nextRule = rules[j + 1];

        const verifyNextOperator = () => {
            if (nextRule && nextRule.type === 'operator') return String(nextRule.conditionalOperator).toUpperCase()
            return null
        }

        if (currentRule.type === 'conditional') {
            const result = evaluateCondition(currentRule, values, requestSale);

            if (pendingOperator === 'OR') {
                visibility = visibility || result;
                pendingOperator = verifyNextOperator();
            } else if (pendingOperator === 'AND') {
                visibility = visibility && result;
                pendingOperator = verifyNextOperator();
            } else {
                pendingOperator = verifyNextOperator();
                visibility = result;
            }
        } else if (currentRule.type === 'operator') {
            pendingOperator = String(currentRule.conditionalOperator).toUpperCase();
        }

        if (!pendingOperator && !visibility) break;
    }

    return visibility;
};

const ListFields = ({ fields, onChange, values, disabled, requestsaleid, requestSale, contactid, updatedAt, viewRequirementsCounter }) => {
    const [onceBuildModel, setOnceBuildModel] = useState<boolean>(false)
    const [accordionValue, setAccordionValue] = useState<string[]>([])
    const [model, setModel] = useState<any[]>([])

    const getDefaultAccordionValue = (model: any[]) => {
        const accordionValue: string[] = [];

        for (let i = 0; i < model.length; i++) {
            const section = model[i];
            const closeMode = _.get(section, 'closeMode', 'open');

            if (closeMode == 'open') {
                accordionValue.push(`item-${i}`)
            } else if (closeMode == 'closed') {

                let withValues: boolean = false;
                const fields = section.list;

                for (let { type, field } of fields) {
                    const value = values[field];

                    switch (type) {
                        case 'multiselectarray':
                        case 'selectmultiple':
                        case 'multiplefile': {
                            if (!Array.isArray(value) || Array.isArray(value) && value.length == 0) {
                                withValues = false;
                            }
                            break;
                        }
                        default: {
                            if (!!value) withValues = true;
                        }
                    }

                    if (withValues) break;
                }

                if (withValues) {
                    accordionValue.push(`item-${i}`)
                }
            }
        }

        return accordionValue
    }

    useEffect(() => {
        if (fields.length == 0) return;

        let list_model: any[] = []
        let add_array: any[] = []
        let actual_divider: null | string = null;
        let actualCloseMode: string = 'open'

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];

            if (field.type == 'divider' && actual_divider) {
                const closeMode = _.get(field, 'closeMode', 'open');

                list_model.push({
                    title: actual_divider,
                    list: [...add_array],
                    closeMode: actualCloseMode
                })

                add_array = [];
                actual_divider = field.title
                actualCloseMode = closeMode;
            } else if (field.type == 'divider') {
                const closeMode = _.get(field, 'closeMode', 'open');
                actual_divider = field.title
                actualCloseMode = closeMode;
            } else if (field.active) {
                const visibilityRules = _.get(field, 'visibilityRules', []);
                const visibilityRuleType = _.get(field, 'visibilityRuleType', []);
                let visibility: boolean = evaluateRules(visibilityRules, values, requestSale);
                if (visibility || visibilityRuleType == 'disable') add_array.push({ ...field, isValidVisibilityRule: visibility });
            }
        }

        list_model.push({ title: actual_divider, list: add_array, closeMode: actualCloseMode });

        if (!onceBuildModel && list_model.length > 0) {
            setAccordionValue(getDefaultAccordionValue(list_model))
            setOnceBuildModel(true);
        }

        setModel(list_model);
    }, [fields, values, requestSale])

    return (
        <Accordion
            onValueChange={setAccordionValue}
            defaultValue={accordionValue}
            value={accordionValue}
            className="w-full"
            type="multiple"
        >
            {
                model.map((section, i) => {
                    const requiredFields = section.list.filter(({ required, ...rest }) =>
                        ((!('isValidVisibilityRule' in rest) || rest.isValidVisibilityRule) && required));

                    const countRequiredFields = requiredFields.length;
                    const completedFields: number = requiredFields.filter(({ field, type }) => {
                        const value = values[field];

                        switch (type) {
                            case 'select':
                            case "multiselectarray": return [value].filter(Boolean).flat().length > 0;
                            default: return !!value
                        }

                    }).length;

                    const withIncompleteField = (countRequiredFields > completedFields)

                    return (
                        <Paper
                            variant="outlined"
                            key={i}
                            sx={{
                                mb: 2,
                                borderRadius: '8px',
                                borderColor: (viewRequirementsCounter && withIncompleteField) ? '#FF0000' : 'default',
                            }}
                        >
                            <ListItem sx={{ py: 2, width: '100%' }}>
                                <AccordionItem value={`item-${i}`} className="w-full border-b-0 ">
                                    <AccordionTrigger className="w-full py-0">
                                        <div className="flex text-start h-[30px] items-center w-full">
                                            {
                                                (section.title) && (
                                                    <div className="flex items-center gap-4">
                                                        <Typography fontWeight={600} className={`${(viewRequirementsCounter && withIncompleteField) ? 'text-destructive' : ''}`} fontSize={20} >
                                                            {section.title}
                                                        </Typography>
                                                        {
                                                            (viewRequirementsCounter && countRequiredFields > 0) && (
                                                                <p className={`text-xs flex gap-1 items-center ${withIncompleteField
                                                                    ? 'text-destructive'
                                                                    : 'text-green-400'}`
                                                                }>
                                                                    {!(withIncompleteField) && <CheckCircle size={14} />}
                                                                    Campos requeridos completados: {completedFields}/{countRequiredFields}
                                                                </p>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </AccordionTrigger>
                                    <AccordionContent>
                                        <div className="w-full h-auto mt-4">
                                            <Grid container item spacing={2}>
                                                {section.list.map((d) => (
                                                    <ItemField
                                                        key={d.field}
                                                        field={d}
                                                        onChange={onChange(d.type, d.field)}
                                                        value={values[d.field]}
                                                        disabled={disabled || ('isValidVisibilityRule' in d && !d.isValidVisibilityRule)}
                                                        requestData={{
                                                            requestsaleid,
                                                            contactid,
                                                            type: d.type,
                                                            field: d.field,
                                                            updatedAt
                                                        }}
                                                    />
                                                ))}
                                            </Grid>
                                        </div>
                                    </AccordionContent>
                                </AccordionItem>
                            </ListItem>
                        </Paper>
                    )
                })
            }
        </Accordion>
    )
}

const ItemField = ({ field, onChange, value, disabled, requestData }) => {
    return <Grid item xs={12} sm={field.type == 'image' || field.type == 'multiplefile' ? 12 : 6} >
        <ProgramableField
            fieldData={field}
            onChange={onChange}
            value={value}
            disabledValue={disabled}
            requestData={requestData}
        />
    </Grid>
}

export default ListFields